@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.css-1s2u09g-control {
  border-color: white !important;
}

input[type="text"]
{
  border: white !important;
}

button {
  box-shadow: none !important;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
}

a{
  text-decoration: none;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

[type=radio]:checked::before,
[type=checkbox]:checked::before {
  content: "";
  width: 14px;
  height: 14px;
  background-color: #ffa500;
  position: absolute;
  top: 2px;
  left: 2px;
}

#debug, #debug > * {
  /*border: 1px solid red;*/
}
